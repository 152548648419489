import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import StarRatingDisplay from "../StarRatingDisplay";


const propTypes = {
    firstName: PropTypes.string.isRequired,
    onStarRatingClick: PropTypes.func.isRequired,
    ratings: PropTypes.array.isRequired,
};

const styles = theme => ({
    root: {
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    title: {
        marginLeft: theme.spacing(1) / 2,
        [theme.breakpoints.only('sm')]: {
            textAlign: 'left',
        }
    },
    ratings: {
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: `${theme.spacing(1)}px`,
            gridRowGap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.only('xs')]: {
            gridTemplateColumns: '1fr',
            maxWidth: 200,
            gridRowGap: theme.spacing(2),
            margin: `${theme.spacing(1)}px auto 0`,
        },
    },
    starItem: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1) / 2,
        padding: 0,
        marginLeft: `-${theme.spacing(1) / 2}px`,
        cursor: 'pointer',
        marginBottom: `${theme.spacing(1)}px`,
        '& span': {
            cursor: 'pointer',
        },
        [theme.breakpoints.only('xs')]: {
            margin: 0,
            padding: 0,
            minHeight: 'unset',
            '& > div': {
                width: 'auto',
            }
        },
    },
    starItemText: {
        minWidth: theme.spacing(6.5),
    },
});

const WidgetReviewStarRatings = ({ firstName, onStarRatingClick, ratings, classes, width }) => {
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="subtitle1">
                <b>{ `${firstName}'s reviews` }</b>
            </Typography>
            <div className={classes.ratings}>
                {
                    ratings.sort((a, b) => b.type - a.type).map(rating =>
                        <Button
                            key={rating.type}
                            onClick={onStarRatingClick(rating.type)}
                            className={classes.starItem}
                        >
                            <StarRatingDisplay rating={rating.type} pixelSize={isWidthDown('xs', width)? '20px' : '25px'} />
                            <Typography
                                color="primary"
                                className={classes.starItemText}
                                variant="body2"
                            >
                                <b>{ `( ${rating.count} )` }</b>
                            </Typography>
                        </Button>
                    )
                }
            </div>
        </div>
    );
};

WidgetReviewStarRatings.propTypes = propTypes;

export default withStyles(styles)(withWidth()(WidgetReviewStarRatings));
