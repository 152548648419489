import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import ProfileClientReviews from './ProfileClientReviews';
import ProfilePanel from './ProfilePanel';
import WidgetReviewStarRatings from './WidgetReviewStarRatings';
import BreadcrumbsProfilePage from '../../components/Breadcrumbs/BreadcrumbsProfilePage';
import { formatPlural } from '../../utils/utilities';
import { getWidgetReviewsMetaData } from '../../data/metaTagsHelper';
import { getServiceLabelBy } from '../../data/attributeHelper';
import { FINANCIAL_ADVISER } from '../../__constants__';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PageHead from '../../components/PageHead';
import { logoWhite } from '../../assets/images';


const styles = theme => ({
    breadcrumbs: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1),
        },
    },
    root: {
        marginBottom: theme.spacing(3),
    },
    summaryWrapper: {
        width: '100%',
        margin: 0,
    },
    starRatingsWrapper: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            marginBottom: `${theme.spacing(1)}px`,
        },
    },
    titleWrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),

        [theme.breakpoints.down('sm')]: {
            margin: `0 ${theme.spacing(2)}px ${theme.spacing(4)}px`,
            textAlign: 'left',
        },
    },
    title: {
        color: theme.palette.primary.contrastText,
    },
    summaryItem: {
        marginBottom: 0,
    },
    showAllButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

class WidgetReviewsPageContent extends Component {
    state = {
        filteredReviews: null,
        filteredType: '',
        loading: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { filteredReviews, loading } = this.state;

        if (prevState === this.state || !loading) return;

        if (prevState.filteredReviews !== filteredReviews) {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 250);
        }
    }

    getReviewAverageScores = scores => {
        if (!scores) {
            return [
                { type: 5, count: 0 },
                { type: 4, count: 0 },
                { type: 3, count: 0 },
                { type: 2, count: 0 },
                { type: 1, count: 0 },
            ];
        }

        return Object.entries(scores).map(([rating, count]) => ({
            type: Number(rating),
            count,
        }));
    };

    onStarRatingClick = type => e => {
        let filteredReviews = [];
        const { filteredType } = this.state;

        if (filteredType === type) return;

        this.setState({ loading: true });

        this.props.reviews.forEach(review => {
            if (Math.round(review.average_score) === type) {
                filteredReviews.push(review);
            }
        });

        this.setState({
            filteredReviews,
            filteredType: type,
        });
    };

    showAllReviews = () => {
        this.setState({
            filteredReviews: null,
            filteredType: '',
            loading: true,
        });
    };

    render() {
        const { filteredReviews, filteredType, loading } = this.state;
        const {
            classes,
            profileData,
            reviews,
            servicesSummary,
            savingsSummary,
            reviewsTotalCount,
            averageScores,
        } = this.props;

        return (
            <Fragment>
                {
                    profileData &&
                    <PageHead {...getWidgetReviewsMetaData(profileData, reviewsTotalCount)} >
                        <meta property='og:image' content={logoWhite} />
                    </PageHead>
                }
                <div className={classes.breadcrumbs}>
                    <BreadcrumbsProfilePage
                        region={profileData.permalink.region}
                        city={profileData.permalink.city}
                        town={profileData.permalink.town}
                        vertical_id={profileData.vertical_id}
                        professionalName={profileData.full_name}
                    />
                </div>
                <Grid container spacing={0} className={classes.root}>
                    <Grid item lg={5} md={12} sm={12} xs={12}>
                        <ProfilePanel data={profileData} showButtons />
                    </Grid>
                    <Grid item lg={7} md={12} sm={12} xs={12}>
                        <Grid container spacing={2} className={classes.summaryWrapper} justify="space-between">
                            <Grid item md={3} sm={4} xs={12} className={classes.starRatingsWrapper}>
                                <WidgetReviewStarRatings
                                    firstName={profileData.first_name}
                                    ratings={this.getReviewAverageScores(averageScores)}
                                    onStarRatingClick={this.onStarRatingClick}
                                />
                            </Grid>
                            {
                                profileData.vertical === FINANCIAL_ADVISER &&
                                <Fragment>
                                    <Grid item md={4} sm={4} xs={6}>
                                        <Typography variant="subtitle1">
                                            <b>Reviewed for</b>
                                        </Typography>
                                        {
                                            servicesSummary &&
                                            Object.entries(servicesSummary).map(([key, count]) =>
                                                <Typography className={classes.summaryItem} variant="body2" paragraph key={key}>
                                                    {`${getServiceLabelBy('id', 5, key)} ( ${count} )`}
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={6}>
                                        <Typography variant="subtitle1">
                                            <b>Reviewers' assets</b>
                                        </Typography>
                                        {
                                            savingsSummary &&
                                            Object.entries(savingsSummary).map(([key, count]) =>
                                                <Typography className={classes.summaryItem} variant="body2" paragraph key={key}>
                                                    {`${key} ( ${count} )`}
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <ProfileClientReviews
                    profileData={profileData}
                    reviews={filteredReviews ? filteredReviews : reviews}
                    reviewsTotalCount={reviewsTotalCount}
                    profileURL={profileData.permalink.url}
                    showReadMoreButton={false}
                    hasReviews={!_.isEmpty(filteredReviews)}
                    loading={loading}
                    profileName={profileData.first_name}
                >
                    {
                        filteredReviews &&
                        <div className={classes.titleWrapper}>
                            <Typography variant="h6" className={classes.title} gutterBottom>
                                {`Showing results for ${profileData.first_name} with ${filteredType} ${formatPlural(filteredType, 'star', 'stars')}`}
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.showAllReviews}
                                className={classes.showAllButton}
                            >
                                Show all reviews
                            </Button>
                        </div>
                    }
                </ProfileClientReviews>
            </Fragment>
        );
    }
}

export default withStyles(styles)(WidgetReviewsPageContent);
